<template>
  <div class="pro_box">
    <div class="title flex_center_between_box">
      <div class="title_txt flex_center_start_box"><img src="../../assets/img/detail/title_ic13.png" />Country Business</div>
      <div class="btn_export flex_center" @click="viewHistory"><img src="../../assets/img/detail/btn_ic4.png" />View History</div>
    </div>
    <div class="right_cont_box">
      <div class="group profile">
        <div class="item">
          <label class="lab">Employment: </label>
          <div class="txt">{{ objBusiness.employmentRate ? objBusiness.employmentRate : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Unemployment: </label>
          <div class="txt">{{ objBusiness.unemploymentRate ? objBusiness.unemploymentRate : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Composite PMI: </label>
          <div class="txt">{{ objBusiness.compositePmi ? objBusiness.compositePmi : '-' }}</div>
          <div class="num">
            {{ objBusiness.compositePmiGrowthRate }}
            <img v-if="!objBusiness.compositePmiGrowthRate.includes('-')" src="../../assets/img/detail/up_img.png" />
            <img v-else src="../../assets/img/detail/down_img.png" />
          </div>
        </div>
        <div class="item">
          <label class="lab">Manufacturing PMI: </label>
          <div class="txt">{{ objBusiness.manufacturingPmi ? objBusiness.manufacturingPmi : '-' }}</div>
          <div class="num">
            {{ objBusiness.manufacturingPmiGrowthRate }}
            <img v-if="!objBusiness.manufacturingPmiGrowthRate.includes('-')" src="../../assets/img/detail/up_img.png" />
            <img v-else src="../../assets/img/detail/down_img.png" />
          </div>
        </div>
        <div class="item">
          <label class="lab">Services PMI: </label>
          <div class="txt">{{ objBusiness.servicesPmi ? objBusiness.servicesPmi : '-' }}</div>
          <div class="num">
            {{ objBusiness.servicesPmiGrowthRate }}
            <img v-if="!objBusiness.servicesPmiGrowthRate.includes('-')" src="../../assets/img/detail/up_img.png" />
            <img v-else src="../../assets/img/detail/down_img.png" />
          </div>
        </div>
        <div class="item">
          <label class="lab">Country Loans to Private Sector: </label>
          <div class="txt">{{ objBusiness.countryLoansToPrivateSector ? objBusiness.countryLoansToPrivateSector : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Corporate Tax Rate: </label>
          <div class="txt">{{ objBusiness.corporateTaxRate ? objBusiness.corporateTaxRate : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Personal Income Tax Rate: </label>
          <div class="txt">{{ objBusiness.personalIncomeTaxRate ? objBusiness.personalIncomeTaxRate : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Sales Tax Rate: </label>
          <div class="txt">{{ objBusiness.salesTaxRate ? objBusiness.salesTaxRate : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Business Confiden: </label>
          <div class="txt">{{ objBusiness.businessConfidence ? objBusiness.businessConfidence : '-' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { searchCountryInfo } from '@/api/companyApi.js'
import crypto from '@/utils/crypto'

export default {
  data() {
    return {
      objBusiness: {
        employmentRate: '',
        unemploymentRate: '',
        compositePmi: '',
        compositePmiGrowthRate: '',
        manufacturingPmi: '',
        manufacturingPmiGrowthRate: '',
        servicesPmi: '',
        servicesPmiGrowthRate: '',
        countryLoansToPrivateSector: '',
        corporateTaxRate: '',
        personalIncomeTaxRate: '',
        salesTaxRate: '',
        businessConfidence: '',
      },
      companyCountry:'',
    }
  },
  computed: {},
  watch: {},

  created() {
    //types: CE CP CB CR CT 首字母缩写枚举
    //countryCodes: US CN
    //years: String 类型
    this.getData()
  },
  methods: {
    getData() {
      this.companyCountry = this.$route.query.companyCountry

      const params = {
        types: ['CB'],
        countryCodes: [this.companyCountry],
        // years: [''],
      }
      searchCountryInfo(params).then((result) => {
        let data = JSON.parse(crypto.decrypt(result))
        if (data && data.code && data.data) {
          console.log('result- business', data.data.countryBusinessList)
          let obj = data.data.countryBusinessList ? data.data.countryBusinessList[0] : ''
          this.objBusiness = {
            employmentRate: obj.employmentRate,
            unemploymentRate: obj.unemploymentRate,
            compositePmi: obj.compositePmi,
            compositePmiGrowthRate: obj.compositePmiGrowthRate,
            manufacturingPmi: obj.manufacturingPmi,
            manufacturingPmiGrowthRate: obj.manufacturingPmiGrowthRate,
            servicesPmi: obj.servicesPmi,
            servicesPmiGrowthRate: obj.servicesPmiGrowthRate,
            countryLoansToPrivateSector: obj.countryLoansToPrivateSector,
            corporateTaxRate: obj.corporateTaxRate,
            personalIncomeTaxRate: obj.personalIncomeTaxRate,
            salesTaxRate: obj.salesTaxRate,
            businessConfidence: obj.businessConfidence,
          }
        }
      })
    },

    //跳转
    viewHistory() {
      this.$router.push({ path: '/country/viewhistory', query: { companyCountry: this.companyCountry } })
    },
  },
}
</script>
<style scoped>
.title {
  margin-bottom: 20px;
}
.title_txt {
  font-size: 16px;
  font-family: 'Arial Bold';
}
.title_txt img {
  width: 20px;
  margin-right: 8px;
}
.pro_box {
  margin-top: 22px;
}
.group.profile {
  padding: 0 10px;
}
.profile .item {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e7eaed;
}
.profile .item:first-child {
  padding-top: 10px;
}
.profile .item:last-child {
  padding-bottom: 10px;
  border-width: 0;
}
.profile .item .lab {
  width: 232px;
  font-size: 16px;
  color: #8497ab;
}
.profile .item .txt {
  width: 190px;
  margin-left: 40px;
  color: #022955;
  font-size: 16px;
}
.profile .item .num {
  margin-left: 50px;
  line-height: 16px;
  font-size: 14px;
  color: #333333;
}
.profile .item .num img {
  width: 10px;
  height: 12px;
}
</style>
